import yourgoal from "../images/works/yourgoal.jpg";
import dope from "../images/works/dope.jpg";
import drakkar from "../images/works/drakkar.jpg";
import veintiuno from "../images/works/veintiuno.jpg";
import verdadesymentiras from "../images/works/verdadesymentiras.jpg";
import estaclaro from "../images/works/estaclaro.jpg";
import hashiban from "../images/works/hashiban.jpg";

export const tempWorks = [
    {
        name: "Your Goal",
        artist: "9th Sea",
        cover: yourgoal,
        to: "https://open.spotify.com/intl-es/track/5lJ6WMhFRGlcDAdoTMIwC6?si=1af6f5c101ad46db",
    },
    {
        name: "La Subasta de Hashiban OST",
        artist: "Jaime Alonso García-Amorena",
        cover: hashiban,
    },
    {
        name: "Verdades & Mentiras EP",
        artist: "Skammer",
        cover: verdadesymentiras,
        to: "https://open.spotify.com/intl-es/album/4EbWeWgHykDRY3HSxKGZWW?si=tiX3TykfQkmXTZQtXD5dzQ",
    },
    {
        name: "21",
        artist: "Skammer",
        cover: veintiuno,
        to: "https://open.spotify.com/intl-es/track/1qc3WdROh3lwOxhhyv7Bt1?si=0f2d356902c04226",
    },
    {
        name: "Está Claro",
        artist: "Skammer",
        cover: estaclaro,
        to: "https://open.spotify.com/intl-es/track/5t8j7oJbjCdcCb3PDUu93K?si=5e9713b4a12a4f34",
    },
    {
        name: "Drakkar",
        artist: "Brisingr",
        cover: drakkar,
        to: "https://open.spotify.com/intl-es/track/0tmXD6FkCg081wFRIskJgf?si=c47113e8e0054975",
    },
    {
        name: "Dope",
        artist: "9th Sea",
        cover: dope,
        to: "https://open.spotify.com/intl-es/track/4JZhUYYcAsxIztmCAOIJt0?si=88a77fb92537477d"
    },
    {
        name: "",
        artist: "",
        cover: "cta",
    },
];

export const works = [
    {
        name: "Your Goal",
        artist: "9th Sea",
        cover: yourgoal,
        to: "https://open.spotify.com/intl-es/track/5lJ6WMhFRGlcDAdoTMIwC6?si=1af6f5c101ad46db",
    },
    {
        name: "La Subasta de Hashiban Banda Sonora Original",
        artist: "Jaime Alonso García-Amorena",
        cover: hashiban,
    },
    {
        name: "Verdades & Mentiras EP",
        artist: "Skammer",
        cover: verdadesymentiras,
        to: "https://open.spotify.com/intl-es/album/4EbWeWgHykDRY3HSxKGZWW?si=tiX3TykfQkmXTZQtXD5dzQ",
    },
    {
        name: "21",
        artist: "Skammer",
        cover: veintiuno,
        to: "https://open.spotify.com/intl-es/track/1qc3WdROh3lwOxhhyv7Bt1?si=0f2d356902c04226",
    },
    {
        name: "Está Claro",
        artist: "Skammer",
        cover: estaclaro,
        to: "https://open.spotify.com/intl-es/track/5t8j7oJbjCdcCb3PDUu93K?si=5e9713b4a12a4f34",
    },
    {
        name: "Drakkar",
        artist: "Brisingr",
        cover: drakkar,
        to: "https://open.spotify.com/intl-es/track/0tmXD6FkCg081wFRIskJgf?si=c47113e8e0054975",
    },
    {
        name: "Dope",
        artist: "9th Sea",
        cover: dope,
        to: "https://open.spotify.com/intl-es/track/4JZhUYYcAsxIztmCAOIJt0?si=88a77fb92537477d"
    },
];

