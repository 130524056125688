import yourgoal from "../images/works/yourgoal.jpg";
import dope from "../images/works/dope.jpg";
import drakkar from "../images/works/drakkar.jpg";
import veintiuno from "../images/works/veintiuno.jpg";
import verdadesymentiras from "../images/works/verdadesymentiras.jpg";
import estaclaro from "../images/works/estaclaro.jpg";
import hashiban from "../images/works/hashiban.jpg";
import logo from "../images/logo.webp";

export const tempReviews = [
    // {
    //     name: "El Boske",
    //     rol: "Estudio de Mezcla",
    //     stars: 5,
    //     review: "Aún no hay reseñas",
    //     work: logo,
    // },
    {

        name: "Gonzalo",

        rol: "Fundador de Hashiban",

        stars: 5,

        review: "Jaime se encargó de componer y producir la banda sonora para mi juego de mesa. Eligió una paleta de sonidos medievales y entregó un album con 9 composiciones que satisfaceron con creces mis más altas expectativas. Jaime es un músico versátil y experimentado que domina el arte de la producción, como pude comprobar en el estudio. Muy recomendado",

        work: hashiban,

    },
    {

        name: "Brisingr",

        rol: "Banda de metal",

        stars: 5,

        review: "El estudio se encargó de gran parte de la producción del tema, así como de la masterización, mostrando una gran profesionalidad y creatividad en su trabajo. La experiencia y el proceso fue muy agradable.",

        work: drakkar,

    },
    {

        name: "Adrián",

        rol: "Productor",

        stars: 5,

        review: "Trabajar con Jaime fue genial: fluido y comunicativo. El sonido final transmite lo que queríamos y además también puede guiar en el proceso de subirla a todas las plataformas. ¡Trabajaré con él de nuevo sin duda!",

        work: dope,

    },
];

export const reviews = [
    // {
    //     name: "El Boske",
    //     rol: "Estudio de Mezcla",
    //     stars: 5,
    //     review: "Aún no hay reseñas",
    //     work: logo,
    // },
    {

        name: "Gonzalo",

        rol: "Fundador de Hashiban",

        stars: 5,

        review: "Jaime se encargó de componer y producir la banda sonora para mi juego de mesa. Eligió una paleta de sonidos medievales y entregó un album con 9 composiciones que satisfaceron con creces mis más altas expectativas. Jaime es un músico versátil y experimentado que domina el arte de la producción, como pude comprobar en el estudio. Muy recomendado",

        work: hashiban,
    },
    {

        name: "Brisingr",

        rol: "Banda de metal",

        stars: 5,

        review: "El estudio se encargó de gran parte de la producción del tema, así como de la masterización, mostrando una gran profesionalidad y creatividad en su trabajo. La experiencia y el proceso fueron muy agradables.",

        work: drakkar,

    },
    {

        name: "Adrián",

        rol: "Productor",

        stars: 5,

        review: "Trabajar con Jaime fue genial: fluido y comunicativo. El sonido final transmite lo que queríamos y además también puede guiar en el proceso de subirla a todas las plataformas. ¡Trabajaré con él de nuevo sin duda!",

        work: dope,

    },
    {

        name: "SKAMMER",
        
        rol: "Compositor , cantante ",
        
        stars: 5,
        
        review: "Muy buen estudio, apartado del ruido, ambiente muy tranquilo cerca del bosque y equipado con buenos periféricos. El dueño muy bueno y majo y el resultado increíble, recomiendo",
        
        work: verdadesymentiras,
        
        },
];