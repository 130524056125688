const spanish = {
    headerButton1: "Inicio",
    headerButton2: "Tarifas",
    headerButton3: "Catálogo",
    headerButton4: "Reseñas",
    headerButton5: "Contacto",
    headerButton6: "Servicios",

    heroTitle: "ESTUDIO DE MEZCLA",
    heroSubtitle: "PRODUCCIÓN, MEZCLA Y MASTERIZACIÓN A TU ALCANCE",

    serviceCard1: "PRODUCCIÓN",
    serviceCard2: "MEZCLA",
    serviceCard3: "MASTERIZACIÓN",

    serviceContent1: "Dale un vuelco a tu carrera musical con nuestros descuentos.",
    serviceContent2: "Tratado, Cuidado, Refinado. El mejor sonido disponible ahora.",
    serviceContent3: "Tu arte lo es todo; consigue que tus oyentes lo disfruten al máximo.",

    serviceSection1: "TARIFAS",
    serviceSection2: "SONIDO",
    serviceSection3: "CATÁLOGO",

    studio: "El estudio está equipado con una mesa de mezclas digital Softube Mixing System, Altavoces Focal Alpha Evo Twin, Tratamiento acústico y software de configuración acústica (Sonarworks Reference ID), instrumentos físicos y digitales, micrófonos AKG y SE Electronics, plug-ins de producción, edición, mezcla y mastering y Cubase 13 Pro",

    serviceOther: "* Para otros servicios (grabación, beatmaking, composición...etc) consultar directamente.",

    sectionSubText1: "¿Tienes una canción grabada pero no suena como te imaginas? Accede a producción, mezcla y masterización musical de la mejor calidad a un precio inigualable.",
    sectionSubText2: "Un estudio cargado de la tecnología más puntera y mejoras para el sonido dirigido por Jaime Alonso, ingeniero de sonido por Abbey Road, para darte lo mejor.",
    sectionSubText3: "¿Tienes una banda, haces música electrónica, eres un cantautor, un rapero? Descubre que no hubo límites para los artistas que ya han pasado por aquí.",

    sectionButton1: "Descubre nuestras tarifas",
    sectionButton2: "Consulta nuestros servicios",
    sectionButton3: "Explora nuestro catálogo", //Escucha Nuestros Trabajos

    checkCard1: "ONLINE",
    checkCard2: "PROFESIONAL",
    checkCard3: "REVISIONES",
    checkCard4: "COMPROMISO",

    studioCardTitle: "El mejor sonido",
    studioCardSubtitle: "Tratado, cuidado, refinado.",

    studioCard1: "Tratamiento Acústico",
    studioCard2: "Monitorización Profesional",
    studioCard3: "Tecnología de Calibración",
    studioCard4: "Entorno de Mezcla",

    workCta: "¿Te unes?",

    seeMore: "Ver Catálogo Completo",
    writeReview: "Escribe una reseña",
    writeReviewSubtitle: "¿Has terminado un proyecto?",
    seeMoreReviews: "Ver todas las reseñas",

    production: "Producción",
    mixing: "Mezcla",
    mastering: "Masterización",

    buttonFinish: "Terminar",
    buttonNext: "Siguiente",
    buttonPrev: "Anterior",

    servicesTitle: "Servicios",
    trackNumber: "Número de obras",
    stemNumber: "Número de pistas por obra",

    // productionContent1: "¿Tú proyecto necesita PRODUCCIÓN?",
    production: [
        {
            s: "arreglos"
        },
        {
            s: "instrumentos"
        },
        {
            s: "síntesis"
        },
        {
            s: "voces extra"
        },
        {
            s: "diseño de sonido"
        },
        {
            s: "efectos"
        },
        {
            s: "automatización creativa"
        }
    ],

    // mixingContent1: "¿Tú proyecto necesita MEZCLA?",
    mixing: [
        {
            s: "balance"
        },
        {
            s: "ajuste de tempo"
        },
        {
            s: "correción de tono"
        },
        {
            s: "ecualización"
        },
        {
            s: "compresión"
        },
        {
            s: "panoramización (profundidad y estéreo)"
        },
        {
            s: "edición de audio"
        },
        {
            s: "efectos de mezcla (reverberación, eco, distorsión...etc)"
        },
        {
            s: "automatizaciones"
        },
        {
            s: "ajuste de sonido de bateria (samples y kits)"
        },
    ],

    // masteringContent1: "¿Tú proyecto necesita MASTERIZACIÓN?",
    mastering: [
        {
            s: "balance frecuencial"
        },
        {
            s: "empuje del volumen para streaming"
        },
        {
            s: "configuración de metadatos"
        },
        {
            s: "refinamiento técnico"
        },
        {
            s: "potenciación de puntos fuertes (rebote, dinámicas, color, brillo, amplitud...etc)"
        },
        {
            s: "entrega de pista estereo, pistas separadas o stems"
        },
    ],

    makeYourPricing: "CREA TU PRESUPUESTO",

    pricing: "Tarifas",
    pricingDiscount: "5% menos del total por cada condición cumplida:",
    discounts: [
        {
            condition: "Si es tu primer trabajo con nostros",
        },
        {
            condition: "Si eres un artista emergente con 15 o menos obras publicadas",
        },
        {
            condition: "Si tu proyecto es muy sencillo (rapero, solista o dúo)",
        },
        {
            condition: "Si tu proyecto es puramente música folklórica",
        },
        {
            condition: "Si estás trabajando en un álbum acústico",
        },
        {
            condition: "Si eres una banda de 4 o más integrantes",
        },
        {
            condition: "Si tu proyecto es una versión o cover de una canción existente",
        },
        {
            condition: "Si tu proyecto es música experimental (a oidos del estudio)",
        },
        {
            condition: "Si tu proyecto es una grabación en vivo",
        },
        {
            condition: "Si tu proyecto está destinado a un cortometraje, documental o proyecto audiovisual",
        },
        {
            condition: "Si en tu proyecto hay obras instrumentales"
        },
        {
            condition: "Si has hecho 5 o más trabajos con nosotros",
        },
        {
            condition: "Si tu proyecto implica una colaboración entre dos artistas",
        }
    ],


    catalogueTitle: "Catálogo",

    contact: "Contacto",
    toContact: "Contactar",
};

export default spanish;